@font-face
{
 font-family: Helvetica;
}

.magestore-popup_social {
    max-width: 565px !important;
}
.clear{
 width:100%;
 height:0px;
 clear:both;
 font-size:0px;
 line-height:0px;
}

#sociallogin_button{
	margin-top:10px;
}

#sociallogin_button button span{
	box-sizing: border-box;
}

#sociallogin_button #bt-loginlj span span, #bt-loginlj-popup span span{background: none repeat scroll 0 0 #143ea9;}

#sociallogin-other-div{	
	display: block;	
	float: right;      
}
#sociallogin-other-a{
	width: 91px;
	display: block;
}
.sociallogin-other{
	display: block;	
	float: right;
    width: 100%;
}
.sociallogin-other-span{
	cursor: pointer;    
	font-weight: bold;
    white-space: nowrap;
}

.sociallogin-other-span-img-popup{	
	float: right;
	width: 26px;
	height: 16px;
	cursor: pointer;        
	/* background: url("sociallogin/add.png") no-repeat right; */
}

.other-button{
	/* margin: 7px 3px 5px 4px; */
}
.sociallogin-other-button{	
    width: auto;
	max-width:375px;
}
button.bt-login-social{background:none;border:none;cursor: pointer;}
button.bt-login-social:hover{opacity: 0.9;}
button.bt-login-social span{	
	height:35px;
	line-height:35px;
	color:#fff;
	padding:0 0 0 35px;
	float:left;
	}

#sociallogin_button .sociallogin-other #other_sociallogin_label{font-family: Open sans;}
button.bt-login-social span span{
	background-position:100% 0 !important;
	/* padding-right:10px !important; */
	padding-left:10px !important;
	text-align: left;
	color:#fff;
	min-width: 110px;
	font-family: Open sans;
    font-size: 12px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: regular;
	text-transform:none;
}
button.bt-login-social span span {
	min-width: 134px;
}
	
/* change poppup */
#magestore-popup{
	padding: 10px;
    position: fixed;
    width: 706px;
    z-index: 9990;
	background: #fff;
	border: 2px;
	min-width: 200px;
	/* border-radius: 7px; */
}
#magestore-login-social{
	float: right;
	width: 34%;
	/* text-align: center; */
}
#bg_fade {
    background-color: #000000;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1000;
	opacity: 0.3;
}
#sociallogin-close{
	cursor: pointer;
    height: 33px;
    position: absolute;
    right: -20px;
    top: -16px;   
    z-index: 9999;
	right: -12px;
	top: -15px;
	height: 34px;
	width: 33px;
	/* background:url("sociallogin/close.png") no-repeat; */
}
#sociallogin-close:hover{
	text-decoration: underline;
}
#magestore-login-form{
	margin-left: 10px;
}
.magestore-loign-h3{
	margin-bottom: 18px;
	border-bottom: 1px solid #CCCCCC;
	font-family: 'tahoma',Arial,Helvetica,sans-serif;
    font-size: 15px;
	padding-bottom: 3px;
}
.magestore-login-input-box{
	margin-bottom: 12px;
	height: 30px;
}
.login-label{
	float: left;	
	padding-top: 5px;
    width: 32%;
	font-weight: bold;
	color: #666;
}
#magestore-popup .form-list label{float: left;text-align: left;}
#magestore-popup .form-list .sociallogin-field {clear: none;}
.login-label em{
	color: red;
}
.sociallogin-input-box{
	float: right;
	width: 246px;
	margin-bottom: 12px;
}
.sociallogin-input-box .input-text{
	border: 1px solid #CCCCCC;
    border-radius: 2px 2px 2px 2px;
    box-shadow: 0 2px 2px #E3E3E3 inset;
    font-family: inherit;
    font-size: 1.1em;
    margin: 0;
    padding: 5px 4px;
    width: 230px;
}
.magestore-login-popup-button{
	float: right;
}
#magestore-forgot-password, #magestore-forgot-back{
	margin-right: 69px;
	font-size: 10px;
	float: left;
	width: 100%;
}
#magestore-forgot-back{
	margin-right: 40px;
}
.popup_click_btn{
	margin-right: 10px !important;
	float: right;
}
.magestore-login-input-box  .login-label em{	
}
.ajax-login-image{
	/* background: url("sociallogin/opc-ajax-loader.gif") no-repeat scroll 0 0 transparent; */
	width: 16px;
	height: 16px;
	margin: 5px 0 5px 152px;
}
.magestore-invalid-email{
	margin-left: 152px;
	color: red;
}

#sociallogin-forgot{
	margin-bottom : 20px;
}
.magestore-sociallogin-create-new-customer{
	float: left;	
	clear: both;
}
.magestore-sociallogin-create-new-customer a{	
	margin-left: 10px;
	font-weight: bold;
}
.sociallogin-field{
	width: 218px !important;
	float: left;
}
.sociallogin-field .input-text{
	width: 168px  !important;
}
#magestore-create-back{
	font-size: 10px;
}
.sociallogin-other-button-popup{
	float: left;
}
#magestore-login-social  .magestore-loign-h3{text-align:center;}
#sociallogin_button ul.right{float:right;}
#sociallogin_button ul.left{float:left;}



@font-face
{
 font-family: Helvetica;
}
#sociallogin_button #magestore-popup_social ul li button{
	width: 175px;height:37px;
}
#sociallogin_button #magestore-popup_social ul li {
    float:left;
	font-family: Open sans;
}
@media all and (min-width: 0px) and (max-width: 680px){
#magestore-login-social .magestore-login-social li,.sociallogin-other ul li.other-button{padding: 0px 0px 10px 0px;}
#magestore-popup_social {
    font-size: 1.5em !important;
}
}
@media (min-width: 681px) {
#magestore-login-social .magestore-login-social li,.sociallogin-other ul li.other-button{padding: 0px 0px 10px 10px;}
}
@media all and (min-width: 0px) and  (max-width: 459px) {
	#sociallogin_button #magestore-popup_social ul li{padding: 0px 0px 10px 0px;}
	#sociallogin_button ul li{padding: 0px 0px 10px 0px;}
}
@media (min-width: 460px) {
	#sociallogin_button #magestore-popup_social ul li{padding: 0px 0px 10px 10px;}
	#sociallogin_button ul li{padding: 0px 0px 10px 10px;}
}
ul.magestore-login-social li{background:#fff;border:none;}

#magestore-popup{
	width:66% !important;
	z-index:99999;
	max-width: 587px;
}
#magestore-popup_social {
	text-align: center;
	font-size: 2.5em;
	line-height: 1.25em;
	margin-bottom: 0.7em;
	position: absolute;
	top: 100px !important;
	left: 0;
	z-index: 100000;
	background-color: white;
}
#sociallogin_button ul.right li{
    float: right;
}
.magestore-popup_social{
		width: 740px !important;
	}
#other_sociallogin_label {
	background-color: rgb(240, 240, 237);
	line-height: 2em;
}
#magestore-popup_social li{
/* 	margin: 0px !important;
	padding: 0px !important; */
}

ul.magestore-login-social li.button{
    list-style: none !important;
}
ul.magestore-login-social li.button:hover{
	background:none !important;
	margin:0;
	border:none;
    list-style: none !important;
}
.sociallogin-input-box .input-text{
	box-shadow: none;
}
.sociallogin-other-button-popup li.button{
	background:none;
	border:none;
	list-style: none !important;
	/* padding: 0px 4px 0px 5px !important; */
}
.sociallogin-other-button-popup li.button:hover{
	background:none;
	border:none;
        list-style: none !important;
}
.other-button{padding:0;}
.bt-login-social{padding:0;margin:0 !important;}
.top-container{width:100%;}

#sociallogin_button{
	float:left;
}
#sociallogin_button ul li,.sociallogin-other ul li{margin: 0px;}
#sociallogin_button ul li.button,.sociallogin-other ul li.button{
	background:none !important;
	border:none !important;
	list-style: none !important;
}
#sociallogin_button ul li.button,.sociallogin-other ul li{}
#sociallogin_button ul li.button:hover{
	background:none !important;
	border:none !important;
	list-style: none !important;
}
.sociallogin-other-button{
	_display:block !important;
	z-index:3000 !important;
}
#sociallogin-other-a{
	display: inline-block;
	width:100%;
	text-align:right;
}
#sociallogin-other-div{
	display: inline-block;
	width:100%;
	margin:0;
}
#sociallogin-other-b{
	display:block;
	height:30px;
	line-height:30px;
}
#sociallogin-other-b span{
	display:block;
	margin-right:30px;
	text-align:right;
}
#sociallogin-other-button{
	top:30px;
}

#magestore-popup{
	top:100px !important;
	position:absolute !important;
	z-index:40020 !important;
}
#magestore-login-form .magestore-invalid-email{
	margin:0;
}
.other-button{
/* 	margin: 0px 2px 0px 2px !important;
	padding: 3px 0px 0px 8px!important; */
}

#bg_fade{
	z-index:30020 !important;
	float:left;
	width:100%;
	min-height:3000px;
}
.active .sociallogin-other-span-img-popup{	
	float: right;
	width: 26px;
	height: 16px;
	cursor: pointer;        
	/*background: url("sociallogin/sub.png") no-repeat right;*/
}

@media all and (min-width: 0px) and (max-width: 680px) {}
@media screen and (max-width:719px) {
	.top-container{
		margin:0 auto;
	}
	
	#magestore-login-social{
		float:left !important;
		width:100%;
	}
	#magestore-login-form{
		float:left !important;
		width:100%;
		margin:0;
	}
	.sociallogin-input-box{
		float:left;
		width:100%;
	}
	.magestore-login-input-box label{float:left;width:100% !important;}
	.sociallogin-input-box .input-text{
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		width:100%;
		box-shadow: none;
		height:30px;
	}
	#magestore-create-user{
		float:left !important;
		width:100%;
	}
	.form-list .sociallogin-field{float:left !important;width:100% !important;}
	.form-list .sociallogin-field .input-text{
		float:left;
		width:100% !important;
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		height:30px;
	}
	.sociallogin-field label{float:left;/* width:100%; */}
	#sociallogin-other-button li.other-button{
		float:none;
		margin:0;
		text-align:center;
	}
	#sociallogin-other-button-popup li.button{
		float:none;
		text-align:center;
		width:100%;
	}
	
	ul.magestore-login-social li.button{
		text-align:center !important;
		width:100%;
	}
	.magestore-popup_social{
		width: 380px !important;
	}
}
@media screen and (max-width:479px) {
	.magestore-popup_social{
		width: 210px !important;
	}
	#magestore-forgot-password, #magestore-forgot-back{
		float:left !important;
		margin:10px 0 !important;
		width:100%;
	}

	.page-gray {
		padding: 0;
	}

	#sociallogin_button ul li.button{
		margin:0;
		text-align:center;
		padding-right: 5px;
		list-style: none !important;
	}

	#sociallogin_button {
		margin-top: 0;
	}

	.registration .social-login .bt-login-social span { height: 30px !important; background: none !important; }

	#sociallogin-other-button li.other-button{
		float:none;
		margin:0;
		text-align:center;
		width:100%;
        list-style: none !important;
	}
	#sociallogin-other-button-popup li.button{
		float:none;
		text-align:center;
		width:100%;
        list-style: none !important;
	}
}
@media screen and (min-width: 720px) and (max-width: 985px) {
	.top-container{
		margin:0 auto;
	}
	#magestore-login-social{
		float:left !important;
		width:100%;
	}
	#magestore-login-form{
		float:left !important;
		width:100%;
		margin:0;
	}
	.sociallogin-input-box{
		float:left;
		width:100%;
	}
	.magestore-login-input-box label{float:left;width:100% !important;}
	.sociallogin-input-box .input-text{
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		width:100%;
		box-shadow: none;
		height:30px;
	}
	#magestore-create-user{
		float:left !important;
		width:100%;
	}
	.form-list .sociallogin-field{float:left !important;width:100% !important;}
	.form-list .sociallogin-field .input-text{
		float:left;
		width:100% !important;
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		height:30px;
	}
	.sociallogin-field label{float:left;/* width:100%; */}
	#sociallogin-other-button li.other-button{
		/* margin-left:65px !important; */
	}
	ul.magestore-login-social li.button{
		/* text-align:center !important;
		margin-left: 5px; */
		/* width:100%; */
		float:left;
	}
	.magestore-popup_social{
		width: 380px !important;
	}
}
@media screen and (min-width: 986px) {
	#magestore-login-social{
		text-align:center !important;
	}
	ul.magestore-login-social li.button{
	}
	#magestore-login-social .magestore-login-social {width:200px}
}
@media screen and (min-width: 986px) and (max-width: 1235px) {
	/* #magestore-popup{border:1px solid blue;} */
	#magestore-login-social{
		float:left !important;
		width:100%;
		text-align:center !important;
	}
	#magestore-login-form{
		float:left !important;
		width:100%;
		margin:0;
	}
	.sociallogin-input-box{
		float:left;
		width:100%;
	}
	.sociallogin-input-box .input-text{
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		width:100%;
		box-shadow: none;
		height:30px;
	}
	.magestore-login-input-box label{float:left;width:100% !important;}
	#magestore-create-user{
		float:left !important;
		width:100%;
	}
	.form-list .sociallogin-field{float:left !important;width:100% !important;}
	.form-list .sociallogin-field .input-text{
		float:left;
		width:100% !important;
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
		height:30px;
	}
	.sociallogin-field label{float:left;/* width:100%; */}
	#magestore-login-social ul.magestore-login-social li{
		float:left;
	}
	#magestore-login-social ul.magestore-login-social li{ }
	
	.magestore-popup_social{
		width: 563px !important;
	}
}
	
@media screen and (min-width: 1236px) and (max-width: 1585px) {
	.magestore-popup_social{
		width: 740px !important;
	}
}
@media screen and (min-width: 1235px){
	#magestore-login-form {
		max-width: 364px;
	}
	#magestore-create-user{
		max-width: 374px;
	}
	.sociallogin-field {
    max-width: 187px;
	}
}
@media screen and (min-width: 1586px) and (max-width: 1890px) {
.magestore-popup_social{
		width: 740px !important;
	}
}
@media screen and (min-width:1891px) {
.magestore-popup_social{
		width: 740px !important;
	}
}